import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { remark } from 'remark';
import strip from 'strip-markdown';
import AudienceFilters from '@common/components/audience-filters-preview';
import { Trans, useTranslation } from 'react-i18next';
import Icon from '../../../../common/components/icon';
import Tooltip from '../../../../common/components/tooltip';

const previewLength = 60;

const MessageRow = ({ item, openMessage, audiencePreviewData }) => {
  const { t } = useTranslation();

  const formattedDate = item.publish_at
    ? moment(item.publish_at).format('D MMMM, HH:mm')
    : moment(item.created_at).format('D MMMM, HH:mm');
  const percentage = Math.min(Math.round((item.message.seen_count / item.reach_count) * 100), 100);

  const icons = [
    !item.processed && (
      <Tooltip key="processed" title={t('organisation:message_row_scheduled')}>
        <Icon type="access_time" />
      </Tooltip>
    ),
    item.message.attachments > 0 && <Icon key="attachments" type="document" />,
    item.message.poll && <Icon key="poll" type="poll" />,
  ].filter((i) => !!i);

  const [previewText, setPreviewText] = useState(null);

  const { text } = item.message;
  useEffect(() => {
    remark().use(strip).process(text).then((file) => {
      const strippedText = String(file);

      const preview = strippedText.length > previewLength ?
        `${strippedText.substring(0, previewLength)}...` :
        strippedText;

      setPreviewText(preview);
    });
  }, [text, setPreviewText]);

  return [
    <>
      <div className="text">
        {icons}
        {
          text ?
            (typeof previewText === 'string' ? previewText : '') :
            t('organisation:message_row_no_text')
        }
      </div>
      <div>
        <small>
          <a role="button" onClick={() => openMessage(item.id)}>
            <Trans i18nKey="organisation:message_row_view_message" />
          </a>
        </small>
      </div>
      <AudienceFilters
        predicates={item?.audience?.predicates}
        {...audiencePreviewData}
      />
    </>,
    <>
      <Icon type="person" />
      {item.reach_count || 0}
    </>,
    <>
      <Icon type="visibility_off" />
      {percentage || 0}
      %
    </>,
    <>
      <Icon type="highfive" />
      {item.message.likes_count || 0}
    </>,
    <>
      <Icon type="chat" />
      {item.message.comments_count || 0}
    </>,
    <small>
      <Icon type="timeline" />
      {item.message.last_activity_at
        ? moment(item.message.last_activity_at).fromNow()
        : t('organisation:message_row_no_interactions')}
    </small>,
    <small>
      <Icon type="calendar_today" />
      {!item.processed && t('organisation:message_row_planned')}
      {' '}{formattedDate}
    </small>,
  ];
};

export default MessageRow;
