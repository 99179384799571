import * as React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { translateMessage } from '@modules/social/actions';
import * as Alert from '../../../../common/services/alert';
import Modal from '../../../../common/components/modal';
import Card from '../../../../common/components/card';
import Spinner from '../../../../common/components/spinner';
import MessageItem from '../../../social/components/feed-item/message';
import * as messagesSelector from '../../selectors/messages';

class OrganisationMessageComponent extends React.Component {
  constructor(props) {
    super();

    this.handleDelete = () => {
      try {
        props.onDelete(props.match.params.messageId);

        props.onClose();
        Alert.success(props.t('organisation:delete_message_success'));
      } catch (response) {
        Alert.forStatus(response.status_code, {
          warning: props.t('core:delete_message_warning'),
          error: props.t('core:delete_message_error'),
        });
      }
    };
  }

  componentDidMount() {
    const { match, organisationMessage, fetchMessage } = this.props;

    if (!organisationMessage) fetchMessage(match.params.messageId);
  }

  render() {
    const { organisationMessage, onClose, onEdit, ...props } = this.props;

    if (organisationMessage) {
      organisationMessage.message.actions = {
        can_delete: true,
        can_edit: true,
        can_mark_important: true,
        can_mute: true,
        can_see_stats: true,
      };
    }

    return (
      <Modal
        list
        className="Messages__Message"
        onExited={onClose}
        content={!organisationMessage ? (
          <Card>
            <Card.Content>
              <Spinner centered size="large" />
            </Card.Content>
          </Card>
        ) : (
          <MessageItem
            showComments={organisationMessage.processed && organisationMessage.message.can_comment}
            disableActions={!organisationMessage.processed}
            {...props}
            item={organisationMessage.message}
            onEdit={() => onEdit(organisationMessage.id)}
            onDelete={this.handleDelete}
          />
        )}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  organisationMessage: messagesSelector.item(state, props.match.params.messageId),
});

const mapDispatchToProps = {
  fetchMessage: require('../../actions').fetchMessage,
  onDelete: require('../../actions').deleteMessage,
  onHighFive: require('../../../social/actions/highfive-message').default,
  onDeleteComment: require('../../../social/actions/delete-comment').default,
  getComments: require('../../../social/actions/fetch-comments').default,
  translateMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrganisationMessageComponent));
