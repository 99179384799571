import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import SearchBar from '@common/components/search-bar';
import { Trans, useTranslation } from 'react-i18next';

import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import Container from '../../../../common/components/container';
import Permission from '../../../../common/components/permission';
import Overview from '../../../../common/components/overview';
import { AsyncTable, TableDropdown } from '../../../../common/components/table';
import Confirm from '../../../../common/components/confirm-button';
import Bar from '../../../../common/components/bar';
import Tooltip from '../../../../common/components/tooltip';
import Icon from '../../../../common/components/icon';
import { Button } from '../../../../common/components/button';
import { Select } from '@common/components/form/inputs/select';

import MessageComponent from '../../components/message';
import messageRow from '../../components/message-row';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import * as userSelector from '../../../core/selectors/logged-user';
import * as messagesSelector from '../../selectors/messages';
import { EPermissions } from '../../../../common/definitions';
import { EOrganisationMessagesFilterTypes } from '../../definitions';
import type { StoreState } from '../../../../common/types/store';
import type { LoggedUser, Organisation, History } from '../../../../common/types/objects';
import type { OrganisationMessage } from '../../../organisation/types/objects';
import './organisation-messages.scss';
import { useFiltersDefaultData } from '@common/components/audience-filters-preview';

require('../../../social/components/feed-item/message/styles.scss');

type MessageFilterOption = {
  label: string;
  value: EOrganisationMessagesFilterTypes;
};

type OwnProps = {
  history: History;
};

type Props = OwnProps & {
  loggedUser: LoggedUser,
  organisation: Organisation,
  messages: Array<OrganisationMessage>,
  fetchMessages: (offset: number, filter: any, limit: number) => Promise<void>,
  deleteMessage: (id: string) => Promise<void>,
};

const OrganisationMessages = ({
  history,
  loggedUser,
  organisation,
  messages,
  fetchMessages,
  deleteMessage,
}: Props) => {
  const { t } = useTranslation();
  const [filterType, setFilterType] = useState<MessageFilterOption | null>(null);

  const typeFilterOptions: MessageFilterOption[] = [
    { label: t('organisation:messages_filter_option_all'), value: EOrganisationMessagesFilterTypes.ALL },
    { label: t('organisation:messages_filter_option_scheduled'), value: EOrganisationMessagesFilterTypes.SCHEDULED },
    { label: t('organisation:messages_filter_option_sent'), value: EOrganisationMessagesFilterTypes.SENT },
  ];

  const messageColumns = [{
    label: t('organisation:communication_text'),
  }, {
    label: (
      <span>
        <Trans i18nKey="organisation:communication_reach_title" />
        <Tooltip title={t('organisation:communication_reach_description')}>
          <Icon type="info" />
        </Tooltip>
      </span>
    ),
    size: 100,
    className: 'Message__SendTo',
  }, {
    label: (
      <span>
        <Trans i18nKey="organisation:communication_seen_title" />
        <Tooltip
          title={t('organisation:communication_seen_description')}
        >
          <Icon type="info" />
        </Tooltip>
      </span>
    ),
    size: 100,
    className: 'Message__Read',
  }, {
    label: t('organisation:communication_high_fives'),
    size: 100,
    className: 'Message__Highfives',
  }, {
    label: t('organisation:communication_comments'),
    size: 100,
    className: 'Message__Comments',
  }, {
    label: (
      <span>
        <Trans i18nKey="organisation:communication_last_interaction_title" />
        <Tooltip
          title={t('organisation:communication_last_interaction_description')}
        >
          <Icon type="info" />
        </Tooltip>
      </span>
    ),
    size: 150,
    className: 'hidden-sd hidden-md hidden-ld',
  }, {
    label: t('organisation:communication_date_sent'),
    size: 150,
  }];

  const [search, setSearch] = useState<string | null>('');

  const [audiencePreviewData, setMetas]: any = useFiltersDefaultData();

  const onFetch = useCallback(async (offset, filter, limit) => {
    const res: any = await fetchMessages(offset, filter, limit);
    setMetas((_metas: any) => [..._metas, res.meta]);
    return res;
  }, [setMetas]);

  return (
    <Container name="Messages" className="OrganisationMessages">
      <Route
        path="/admin/content/messages/:messageId"
        render={({ match }) => ( // eslint-disable-line react/jsx-no-bind
          <MessageComponent
            enableOrganisationActions
            history={history}
            match={match}
            loggedUser={loggedUser}
            organisation={organisation}
            onEdit={(id: string) => history.push(`/admin/content/messages/${id}/edit`)}
            onClose={() => history.push('/admin/content/messages')}
          />
        )}
      />
      <Container.Content>
        <Bar>
          <h2>
            <Trans i18nKey="organisation:content_company_news" />
            <a href={t('organisation:content_company_news_learn_more')} target="_blank" rel="noreferrer">
              <Trans i18nKey="core:learn_more" />
            </a>
          </h2>
          <Select
            options={typeFilterOptions}
            onChange={setFilterType}
            value={filterType}
            valueKey="value"
            labelKey="label"
          />
          <Permission name={EPermissions.ORGANISATION_ORGANISATION_MESSAGES_CREATE}>
            <Button
              type="primary"
              size="large"
              iconRight="add"
              onClick={() => history.push('/admin/content/messages/create')}
            >
              <Trans i18nKey="organisation:communication_message_create" />
            </Button>
          </Permission>
        </Bar>

        <SearchBar
          onSearch={setSearch}
          debounce
          placeholder={t('common:search_list_search_placeholder')}
        />

        <Overview>
          <Overview.Content>
            <AsyncTable
              columns={messageColumns}
              // @ts-expect-error
              items={messages}
              disableInitialFetch={messages.length > 0}
              data={{
                onFetch,
                filter: {
                  filter: filterType?.value,
                  q: search
                },
              }}
              renderRow={messageRow}
              rowProps={{
                openMessage: (id: string) => history.push(`/admin/content/messages/${id}`),
                audiencePreviewData
              }}
              placeholder={(
                <Overview.Placeholder>
                  <Trans i18nKey="common:select_no_results" />
                </Overview.Placeholder>
              )}
              ActionComponent={({ item }) => (
                <TableDropdown id={`message-${item.id}`}>
                  <Permission name={EPermissions.ORGANISATION_ORGANISATION_MESSAGES_VIEW}>
                    <TableDropdown.Item onClick={() => history.push(`/admin/content/messages/${item.id}`)}>
                      <Trans i18nKey="organisation:communication_message_action_view" />
                    </TableDropdown.Item>
                  </Permission>
                  <Permission name={EPermissions.ORGANISATION_ORGANISATION_MESSAGES_UPDATE}>
                    <TableDropdown.Item onClick={() => history.push(`/admin/content/messages/${item.id}/edit`)}>
                      <Trans i18nKey="organisation:communication_message_action_update" />
                    </TableDropdown.Item>
                  </Permission>
                  <Permission name={EPermissions.ORGANISATION_ORGANISATION_MESSAGES_REMOVE}>
                    <Confirm
                      danger
                      title={t('social:feed_item_message_organisation_confirm_remove_title')}
                      description={t('social:feed_item_message_organisation_confirm_remove_description')}
                      confirmText={t('social:feed_item_message_default_confirm_remove')}
                      onConfirm={() => deleteMessage(item.id)}
                    >
                      <TableDropdown.Item>
                        <Trans i18nKey="organisation:communication_message_action_remove" />
                      </TableDropdown.Item>
                    </Confirm>
                  </Permission>
                </TableDropdown>
              )}
            />
          </Overview.Content>
        </Overview>
      </Container.Content>
    </Container>
  );
};

const mapStateToProps = (state: StoreState) => ({
  organisation: organisationSelector.selected(state),
  loggedUser: userSelector.selected(state),
  messages: messagesSelector.list(state),
});

/* eslint-disable global-require */
const mapDispatchToProps = {
  fetchMessages: require('../../actions').fetchMessages,
  deleteMessage: require('../../actions').deleteMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  pageWrapper(EEventNames.VISITED_ORGANISATION_MESSAGES_PAGE)(OrganisationMessages),
);
