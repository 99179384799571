export enum EOrganisationMessagesFilterTypes {
  ALL = 'all',
  SCHEDULED = 'scheduled',
  SENT = 'sent',
}

export const MenuItemsTypes = {
  SHORTCUT: 'shortcut',
} as const;

export const ShortcutTypes = {
  EXTERNAL_LINK: 'external_link',
  FORM: 'form',
  DOCUMENT: 'document',
  COMPONENT: 'feature',
} as const;
