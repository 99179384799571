import * as React from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';

import { EOrganisationMessagesPermissions } from '@common/definitions';
import { Api } from '@common/services/api';
import * as userSelector from '@modules/core/selectors/logged-user';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import Container from '../../../../common/components/container';
import Spinner from '../../../../common/components/spinner';
import Bar from '../../../../common/components/bar';
import { Button } from '../../../../common/components/button';
import MessageForm from '../../forms/message';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import * as messagesSelector from '../../selectors/messages';

class OrganisationMessageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      networks: null
    };
  }

  UNSAFE_componentWillMount() {
    const { message, match: { params }, fetchMessage } = this.props;

    if (!message && params.messageId) fetchMessage(params.messageId);
  }

  async componentDidMount() {
    const { showAllNetworks, organisation } = this.props;
    let url = '/v3/organisations/' + organisation.id + '/networks';
    if (showAllNetworks) {
      url += '?include_all=true';
    }
    const res = await Api.get(url);
    this.setState({ networks: res.data });
  }

  static props;

  render() {
    const {
      organisation, functions, message, form, match: { params },
      history, isEdit
    } = this.props;

    const { networks } = this.state;

    return (
      <Container name="CreateMessage">
        <Container.Content horizontal>
          <Container.Column>
            {(!message && params.messageId) || !networks ? (
              <Spinner size="large" centered />
            ) : (
              <>
                <Bar>
                  {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                  <h2 />
                  <Button onClick={() => this.props.history.push('/admin/content/messages')}>
                    <Trans i18nKey="organisation:channel_back" />
                  </Button>
                </Bar>
                <MessageForm
                  isEdit={isEdit}
                  history={history}
                  message={message}
                  organisation={organisation}
                  networks={networks}
                  functions={functions}
                  formValues={form && form.values
                    ? form.values
                    : {
                      filters: {
                        networks: [],
                        functions: [],
                        users: [],
                      },
                    }}
                  onBack={history.goBack}
                />
              </>
            )}
          </Container.Column>
        </Container.Content>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    form: state.form['create-organisation-message'],
    organisation: organisationSelector.selected(state),
    functions: organisationSelector.functions(state),
    message: messagesSelector.item(state, props.match.params.messageId),
    showAllNetworks: userSelector.hasAnyOfPemissions(state, EOrganisationMessagesPermissions)
  };
};

const mapDispatchToProps = {
  fetchMessage: require('../../actions').fetchMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(pageWrapper(EEventNames.VISITED_ORGANISATION_MESSAGE_DETAIL_PAGE)(OrganisationMessageContainer));
