import * as React from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import throat from 'throat';

import { Api } from '@common/services/api';
import { EPredicateFields } from '@common/definitions';
import Container from '../../../../common/components/container';
import Bar from '../../../../common/components/bar';
import { Button } from '../../../../common/components/button';
import Spinner from '../../../../common/components/spinner';
import ChannelForm from '../../forms/channel';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import * as channelSelector from '../../../organisation/selectors/channel';

const fiveATime = throat(5);

const MAX_USERS_LIMIT = 50;

class ChannelFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      injectablePredicateUsers: null
    };
  }

  componentDidMount() {
    const {
      organisation,
      fetchRoles,
      fetchChannels,
      channel,
      match: { params: { channelId } }
    } = this.props;

    if (channelId !== 'create') {
      if (!channel) {
        fetchChannels();
      }

      // fix for PD-8191, we need to fetch users that are in the channel
      // user predicate, because they might miss from redux state
      const userPredicate = channel?.predicates?.find((p) => {
        return p.attribute === EPredicateFields.USER;
      });
      if (userPredicate) {
        const iterations = Math.ceil(userPredicate.value.length / MAX_USERS_LIMIT);
        const payload = {
          predicate_type: 'match_all',
          predicates: [userPredicate]
        };
        const url = '/v1/organisations/' + organisation.id + '/users/search';
        const calls = Array(iterations).fill().map((_, index) => {
          const query = '?offset=' + (index * MAX_USERS_LIMIT);
          return fiveATime(() => Api.post(url + query, payload));
        });
        Promise.all(calls).then((responses) => {
          const users = responses.map((res) => res.data).flat();
          this.setState({ injectablePredicateUsers: users });
        });
      } else {
        this.setState({ injectablePredicateUsers: [] });
      }
    }
    fetchRoles();
  }

  static props;

  render() {
    const { injectablePredicateUsers } = this.state;
    const {
      channel, form, history, match: { params }, getState, ...props
    } = this.props;

    if (params.channelId !== 'create' && (!channel || !injectablePredicateUsers)) {
      return (
        <Container name="Messages">
          <Container.Content>
            <Spinner size="large" centered />
          </Container.Content>
        </Container>
      );
    }

    const values = form
      ? form.values
      : (channel || { theme: {}, settings: {}, filters: { networks: [], functions: [], users: [] } });

    return (
      <Container name="Messages">
        <Container.Content>
          <Bar>
            {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
            <h2 />
            <Button onClick={() => history.push('/admin/content/channels')}>
              <Trans i18nKey="organisation:channel_back" />
            </Button>
          </Bar>
          <ChannelForm
            {...props}
            channel={channel}
            formValues={values}
            onGoBack={() => this.props.history.push('/admin/content/channels')}
            injectablePredicateUsers={injectablePredicateUsers}
            getState={getState}
          />
        </Container.Content>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  organisation: organisationSelector.selected(state),
  admins: organisationSelector.admins(state),
  networks: organisationSelector.networks(state),
  functions: organisationSelector.functions(state),
  channel: channelSelector.item(state, props.match.params.channelId),
  form: state.form.channel,
  getState: () => state
});

const mapDispatchToProps = {
  fetchChannels: require('../../actions').fetchChannels,
  fetchRoles: require('../../../organisation/actions').fetchRoles,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelFormContainer);
