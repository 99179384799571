import React, { memo, useCallback, useMemo } from 'react';
import mergeNameText from '@common/utils/merged-names-text';
import Icon, { IconTypes } from '../icon';
import { useTranslation } from 'react-i18next';
import { Audience } from '@common/types/objects';
import { EPredicateFields } from '@common/definitions';
import _ from 'lodash';

export { default as useFiltersDefaultData } from './use-filters-default-data';

type ItemProps = {
  icon: IconTypes;
  label: (string | JSX.Element)[];
};

const Item = memo(({ icon, label }: ItemProps) => {
  return (
    <div className="Audience__Filters__Item">
      <Icon size={17} type={icon} />
      { label }
    </div>
  );
});

const Filters = memo(({ icon, labels }: any) => {
  if (labels.length <= 0) {
    return null;
  }
  return <Item icon={icon} label={mergeNameText(labels, 3)} />;
});

// TODO finish typing up once compiler functions
type AudienceFiltersPreviewProps = {
  predicates: Audience['predicates'];
  networks: any[];
  functions: any[];
  users: any[];
  languages: any[];
};

function getPredicateValueLabels(
  ids: string[],
  elements: any[], // TODO improve typing
  missingElementPrefix: string,
  elementNameKey: string = 'name'
) {
  const labels = ids.map((id) => {
    return elements.find((element: any) => element.id === id)?.[elementNameKey];
  }).sort().map((name, index) => { // sort places falsey values at the end of the array
    if (typeof name === 'string') {
      return name;
    }
    return `${missingElementPrefix} ${index + 1}`;
  });
  return labels;
}

const AudienceFiltersPreview = memo(({
  predicates = [],
  networks,
  functions,
  users,
  languages
}: AudienceFiltersPreviewProps) => {

  const { t } = useTranslation();

  const getLabels = useCallback((
    predicateName: `${EPredicateFields}`,
    missingElementPrefixTranslation: string,
    elements: typeof networks | typeof functions | typeof users,
    elementNameKey?: string
  ) => {
    const ids = _.uniq(
      predicates
        .filter((pred) => pred.attribute === predicateName)
        .map((predicate) => predicate.value).flat()
    ) as string[];

    return getPredicateValueLabels(
      ids, elements, t(missingElementPrefixTranslation), elementNameKey
    );
  }, [predicates, t]);

  const networkLabels = useMemo(() => {
    return getLabels(
      EPredicateFields.NETWORK,
      'common:predicates_filter_predicate_network',
      networks
    );
  }, [getLabels, networks]);

  const functionLabels = useMemo(() => {
    return getLabels(
      EPredicateFields.FUNCTION,
      'common:predicates_filter_predicate_function',
      functions
    );
  }, [getLabels, functions]);

  const userLabels = useMemo(() => {
    return getLabels(
      EPredicateFields.USER,
      'common:predicates_filter_predicate_user_name',
      users,
      'full_name'
    );
  }, [getLabels, users]);

  const languageLabels = useMemo(() => {
    return getLabels(
      EPredicateFields.LANGUAGE,
      'common:predicates_filter_predicate_language',
      languages,
    );
  }, [getLabels, languages]);

  const daysInService = predicates.find((predicate) => {
    return predicate.attribute === EPredicateFields.DAYS_IN_SERVICE;
  });

  const daysOutService = predicates.find((predicate) => {
    return predicate.attribute === EPredicateFields.DAYS_BEFORE_OUT_OF_SERVICE;
  });

  return (
    <div className="Audience__Filters">
      {
        predicates?.length > 0 ?
          (
            <>
              <Filters icon="storefront" labels={networkLabels} />
              <Filters icon="work" labels={functionLabels} />
              <Filters icon="person" labels={userLabels} />
              <Filters icon="language" labels={languageLabels} />
              {
                daysInService && (
                  <Item
                    icon="calendar_today"
                    label={
                      t('common:predicates_filter_predicate_days_in_service')
                    }
                  />
                )
              }
              {
                daysOutService &&
                (
                  <Item
                    icon="event_busy"
                    label={
                      t(
                        'common:predicates_filter_predicate_' +
                        'days_before_out_of_service'
                      )
                    }
                  />
                )
              }
            </>
          ) : (
            <Item
              icon="business"
              label={t('organisation:communication_channels_everyone')}
            />
          )
      }
    </div>
  );
});

export default AudienceFiltersPreview;
