import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TableDropdown } from '../../../../common/components/table';
import Permission from '../../../../common/components/permission';
import Confirm from '../../../../common/components/confirm-button';
import { EPermissions } from '../../../../common/definitions';

const ChannelActionsComponent = ({ item, onOpen, onDelete }) => {
  const { t } = useTranslation();

  return (
    <TableDropdown id={`channel-${item.id}`}>
      <Permission name={EPermissions.ORGANISATION_CHANNELS_UPDATE}>
        <TableDropdown.Item onClick={onOpen}>
          {t('organisation:channel_actions_edit_button')}
        </TableDropdown.Item>
      </Permission>
      <Permission name={EPermissions.ORGANISATION_CHANNELS_REMOVE}>
        <Confirm
          title={t('organisation:channels_actions_delete_title')}
          description={t('organisation:channels_actions_delete_description')}
          onConfirm={onDelete}
        >
          <TableDropdown.Item danger>{t('organisation:channels_actions_delete_button')}</TableDropdown.Item>
        </Confirm>
      </Permission>
    </TableDropdown>
  );
};

export default ChannelActionsComponent;
