import React from 'react';
import ImageItem from '@common/components/image-item';
import { mergeNamesToString } from '@common/utils/merged-names-text';
import './moderator-row.scss';

export default function ModeratorRow({ item, children }) {
  return (
    <div className="ChannelForm__ModeratorRow">
      <ImageItem name={item.full_name} image={item.profile_img} />
      {children}
      <span>{mergeNamesToString(item?.roles?.map(({ name }) => name) || [])}</span>
    </div>
  );
}
