import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const useFiltersDefaultData = () => {
  // metas is an array of API response's meta objects, which contain the
  // metadata necessary in order to display results in the audience filters preview
  // see the usage in source\shared\modules\forms\containers\forms\forms.tsx
  const [metas, setMetas] = useState<any[]>([]);

  // console.log("debug metas', metas);

  const translations = useSelector((state: any) => state.language.translations);

  const audiencePreviewData = useMemo(() => {
    const getAll = (key: 'networks' | 'functions' | 'users') => {
      const list = metas.map((meta) => meta?.related?.[key]);
      return list.filter(Array.isArray).flat();
    };
    return {
      networks: getAll('networks'),
      functions: getAll('functions'),
      users: getAll('users'),
      languages: translations.map((translation: any) => {
        return {
          id: translation.locale,
          name: translation.name_eng
        };
      })
    };
  }, [metas, translations]);

  return [audiencePreviewData, setMetas, metas];
};

export default useFiltersDefaultData;
