import * as React from 'react';
import { connect } from 'react-redux';
import { Switch, Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';
import Route, { getRedirect } from '../../../../common/components/route';
import Permission from '../../../../common/components/permission';
import { SubNavigationBar } from '../../../../common/components/navigation-bar';
import ChannelsOverviewContainer from '../channels';
import MessagesOverviewContainer from '../messages';
import ChannelFormContainer from '../channel';
import MessageFormContainer from '../message';
import * as userSelector from '../../../core/selectors/logged-user';
import { EPermissions } from '../../../../common/definitions';

require('./styles.scss');

const ContentContainer = ({ match, permissions: allPermissions }) => {
  const { t } = useTranslation();

  const items = [
    {
      permissions: {
        name: EPermissions.ORGANISATION_ORGANISATION_MESSAGES_VIEW,
      },
      icon: 'send',
      path: '/admin/content/messages',
      title: t('organisation:content_company_news'),
    }, {
      permissions: {
        name: EPermissions.ORGANISATION_CHANNELS_VIEW,
      },
      icon: 'campaign',
      path: '/admin/content/channels',
      title: t('organisation:content_channels'),
    },
  ];

  const hasPermission = ({ name }) => allPermissions.includes(name); // eslint-disable-line react/prop-types
  const redirect = getRedirect(items, hasPermission);

  return (
    <>
      <Route
        exact
        path={['/admin/content/messages', '/admin/content/messages/:messageId', '/admin/content/channels']}
        component={() => (
          <SubNavigationBar title={t('core:tab_content')}>
            {items.map(({ permissions, ...item }) => (
              <Permission key={item.path} {...permissions}>
                <SubNavigationBar.Item {...item} />
              </Permission>
            ))}
          </SubNavigationBar>
        )}
      />
      <Switch>
        <Route path="/admin/content/messages/create" component={MessageFormContainer} />
        <Route
          path="/admin/content/messages/:messageId/edit"
          render={(props) => <MessageFormContainer {...props} isEdit />}
        />
        <Route path="/admin/content/messages" component={MessagesOverviewContainer} />
        <Route path={['/admin/content/channels/:channelId', '/admin/content/channels/:channelId/edit']} component={ChannelFormContainer} />
        <Route path="/admin/content/channels" component={ChannelsOverviewContainer} />
        {redirect && match.path !== redirect && <Redirect exact from="/admin/content" to={redirect} />}
      </Switch>
    </>
  );
};

const mapStateToProps = (state) => ({
  permissions: userSelector.permissions(state),
});

export default connect(mapStateToProps)(ContentContainer);
