import * as React from 'react';
import moment from 'moment';
import { Trans } from 'react-i18next';
import mergeNameText from '../../../../common/utils/merged-names-text';
import Icon from '../../../../common/components/icon';
import Image from '../../../../common/components/image';

const showFilters = (icon, filters, key) => filters.length > 0 && (
  <div className="Audience__Filters__Item">
    <Icon size={17} type={icon} />
    {mergeNameText(filters.filter((i) => !!i).map((filter) => filter[key || 'name']), 3)}
  </div>
);

const ChannelRow = ({ item: channel, onOpen }) => [
  <Image rounded src={channel.theme.logo_url} alt={channel.name} size={35} />,
  // TODO Audience__Filters should be replaced, see
  // https://linear.app/oneteam/issue/ONE-545/audience-preview-in-organization-groups-must-use
  <>
    <a onClick={onOpen}>{channel.name}</a>
    <div className="Audience__Filters">
      {showFilters('storefront', channel.filters.networks)}
      {showFilters('work', channel.filters.functions)}
      {showFilters('person', channel.filters.users, 'full_name')}
      {channel.filters.networks.length === 0 && channel.filters.functions.length === 0 && channel.filters.users.length === 0 && (
        <div className="Audience__Filters__Item">
          <Icon size={17} type="business" />
          <Trans i18nKey="organisation:communication_channels_everyone" />
        </div>
      )}
    </div>
  </>,
  <>
    {moment(channel.created_at).format('D MMMM YYYY')}
  </>,
];

export default ChannelRow;
